<template>
  <div v-if="task" class="sub-task">
    <div class="sub-task__index">
      {{ index }}
    </div>
    <div class="sub-task__info-wrapper">
      <div class="sub-task__status" :style="{backgroundColor: taskStatusColor}" />
      <div class="sub-task__info">
        <div class="sub-task__header">
          <div class="sub-task__title">
            {{ task.title }}
          </div>
          <esmp-tag
            v-if="taskStatusName"
            class="sub-task__status-tag"
            :color="taskStatusColor"
            is-status
          >
            {{ taskStatusName }}
          </esmp-tag>
        </div>
        <div class="sub-task__number">
          №{{ task.number }}
        </div>
        <div class="sub-task__bottom">
          <div class="sub-task__bottom-left">
            <div class="sub-task__info-item">
              <div class="sub-task__info-item-label">
                Дата начала
              </div>
              <div class="sub-task__info-item-value">
                {{ new Date(task.createdAt) | dateFormat }}
              </div>
            </div>
            <div class="sub-task__info-item">
              <div class="sub-task__info-item-label">
                Рабочая группа
              </div>
              <div class="sub-task__info-item-value">
                {{ task.supportGroup }}
              </div>
            </div>
          </div>
          <div class="sub-task__bottom-right">
            <esmp-user-avatar
              :username="responsibleName"
              v-if="responsibleName"
              class="sub-task__avatar"
            />
            {{ responsibleName || 'Исполнитель не назначен' }}
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { getStatus } from '@/helpers/status';

export default {
  name: 'SubTask',
  props: {
    task: {
      type: Object,
      default: undefined,
    },
    index: {
      type: Number,
      default: undefined,
    },
  },
  computed: {
    taskStatus() {
      return getStatus(this.task.state);
    },
    taskStatusName() {
      if (!this.taskStatus) return undefined;
      return this.taskStatus.name;
    },
    taskStatusColor() {
      if (!this.taskStatus) return undefined;
      return this.taskStatus.color;
    },
    responsibleName() {
      return this.task
      && this.task.responsible
      && this.task.responsible.fullName
        ? this.task.responsible.fullName
        : null;
    },
  },
};
</script>

<style lang="scss" scoped>
.sub-task {
  margin: 15px 0;
  position: relative;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  &__index {
    padding: 25px 0 0 0;
    margin: 0 16px 0 0;
    font-size: 16px;
    line-height: 24px;
    font-weight: 500;
  }
  &__status {
    position: absolute;
    z-index: 1;
    top: 0;
    left: -8px;
    width: 24px;
    height: 76px;
    border-radius: 8px;
    background-color: #f5f5f5;
  }
  &__info {
    &-wrapper {
      width: 100%;
      position: relative;
    }
    position: relative;
    z-index: 2;
    min-height: 80px;
    padding: 16px 24px;
    background: #FFFFFF;
    border: 1px solid #E8E8EE;
    border-radius: 16px;
    &-item {
      margin: 6px 0 0 0;
      display: flex;
      flex-direction: row;
      align-items: center;
      font-size: 12px;
      line-height: 16px;
      &:first-child {
        margin: 0;
      }
      &-label {
        width: 115px;
        color: #797E8B;
        flex-shrink: 0;
      }
    }
  }
  &__header {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    @include for-size(phone-portrait-down) {
      flex-direction: column-reverse;
      align-items: flex-start;
    }
  }
  &__title {
    font-size: 14px;
    line-height: 20px;
    font-weight: 500;
  }
  &__number {
    font-size: 12px;
    line-height: 16px;
    color: #797E8B;
  }
  &__bottom {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    margin: 8px 0 0 0;
    &-right {
      display: flex;
      flex-direction: row;
      align-items: center;
    }
    @include for-size(phone-portrait-down) {
      display: block;
    }
  }
  &__avatar {
    margin: 0 8px 0 0;
    width: 32px;
    height: 32px;
    padding: 2px;
    background: #fff;
    border: 2px solid #E8E8EE;
  }
}
</style>
